import { Helmet } from "react-helmet"
import { ContactEditFormComponent } from "./components/ContactEditForm.component"

export const ContactComponent = () => {
  return (
    <>
      <Helmet>
        <title>Kontakt z ALUPROFIL</title>

        <meta
          property="og:type"
          content="website"
        />
        
        <meta
          property="og:title"
          content="Kontakt z ALUPROFIL"
        />

        <meta
          property="og:description"
          content="Obsługa sprzedaży profili: +48 22 478 33 03; skrzydeł drzwiowych: +48 22 478 29 50; formatek: +48 504 461 731."
        />

        <meta
          name="description"
          content="Obsługa sprzedaży profili: +48 22 478 33 03; skrzydeł drzwiowych: +48 22 478 29 50; formatek: +48 504 461 731."
        />

        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "Kontakt z ALUPROFIL",
            "description": "Obsługa sprzedaży profili: +48 22 478 33 03; skrzydeł drzwiowych: +48 22 478 29 50; formatek: +48 504 461 731."
          }`}
        </script>
      </Helmet>

      <div className="w-full bg-aluprofil-light">
        <div className="mx-auto container">
          <div className="px-4 py-16">
            <hr className="mb-16 border-t-2 border-aluprofil-gray/20" />

            <h2 className="mb-16 w-full font-head font-normal text-5xl text-aluprofil-gray">
              Kontakt
            </h2>

            <div className="flex flex-row flex-wrap gap-y-16 lg:flex-nowrap lg:gap-x-6">
              <div className="w-full flex flex-col items-center lg:w-1/2">
                <div className="w-full max-w-sm">
                  <ContactEditFormComponent />
                </div>
              </div>

              <div className="w-full flex flex-col items-center lg:w-1/2">
                <div className="w-full max-w-sm space-y-12">
                  <div itemScope itemType="http://schema.org/Organization"
                    className="space-y-2 text-aluprofil-gray"
                  >
                    <p>
                      <span itemProp="name"
                        className="block pb-2 font-semibold border-b-2 border-b-aluprofil-red"
                      >
                        ALUPROFIL Sp. z o.o.
                      </span>
                    </p>

                    <div itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
                      <p>
                        <span itemProp="streetAddress">Plac Czerwca 1976 roku Nr 7</span>
                      </p>

                      <p>
                        <span itemProp="postalCode">02-495</span> <span itemProp="addressLocality">Warszawa - Ursus</span>
                      </p>
                    </div>

                    <p>
                      <a href="phone:+48224783303" itemProp="telephone">
                        Profile: +48 22 478 33 03
                      </a>

                      <br />

                      <a href="phone:+48224782950" itemProp="telephone">
                        Drzwi: +48 22 478 29 50
                      </a>

                      <br />

                      <a href="phone:+48504461731" itemProp="telephone">
                        Formatki: +48 504 461 731
                      </a>
                    </p>
                  </div>

                  <div className="space-y-2 text-aluprofil-gray">
                    <p>
                      <span className="block pb-2 font-semibold border-b-2 border-b-aluprofil-red">
                        Dane rejestracyjne
                      </span>
                    </p>

                    <p>
                      KRS: 0000133457<br />
                      NIP: 522-26-58-441<br />
                      Regon: 015256950<br />
                      BDO: 000057405<br />
                    </p>

                    <p>
                      Sąd Rejonowy dla m.st. Warszawa, XIV Wydział Gospodarczy Krajowego Rejestru Sądowego.
                    </p>

                    <p>
                      Kapitał zakładowy: 51.450,00 PLN.
                    </p>
                  </div>

                  <div className="space-y-2 text-aluprofil-gray">
                    <p>
                      <span className="block pb-2 font-semibold border-b-2 border-b-aluprofil-red">
                        Rachunki bankowe
                      </span>
                    </p>

                    <p>
                      PLN: 43 1240 6175 1111 0000 4567 2545<br />
                      USD: 23 1240 6175 1787 0011 3982 9078<br />
                      EUR: 19 1240 6175 1978 0011 3982 9397<br />
                      GBP: 15 1240 6175 1789 0011 3982 9704
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
